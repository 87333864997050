/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import { toast } from 'react-toastify';
import { handleTrimString, messageErrors } from '../../utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './organizationModal.scss';
import { createOrganizationUser, getUsersByEmail } from 'api/userApi';
import './organizationPatientModal.scss';
import { roleName } from 'utils/constants';
import PatientInviteModal from './patientInviteModal';
import InputText from 'components/inputText';
import '../../styles/styles.scss';
import { getRoles } from 'api/rolesApi';
import GroupButton from 'components/button/groupButton';
import SpinnerComponent from '../../components/spinner';
type organizationModalProps = {
  openModal: boolean,
  setOpenModal: Function,
  targetData?: any,
  headerTitle?: string,
  isEdit: boolean,
  queryClient?: any,
  setCurrentPage?: any,
  currentPage?: number,
};
const OrganizationPatientModal = (props: organizationModalProps) => {
  const { openModal, setOpenModal, targetData, headerTitle, isEdit, queryClient, currentPage, setCurrentPage } = props;
  const { fetchCurrentUser } = useUser();
  const [t] = useTranslation();
  const rootRef = useRef(null);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [isReinvite, setIsReinvite] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data: roles } = useQuery(['getOrganizationRoles'], () => getRoles({ page: 0, limit: 0 }), { staleTime: Infinity });

  const mutaCreatePatient = useMutation('createOrganizationPatient', {
    mutationFn: createOrganizationUser,
    onSuccess: () => {
      toggleHandler();
      const message: string = isReinvite ? t('organizationPatients.reinvited') : t('organizationPatients.addPatientMessage');
      toast.success(message);
      if (currentPage !== 0){
        setCurrentPage(0);
      }
      queryClient.invalidateQueries('getOrganizationPatients', { refetchActive: true }, { cancelRefetch: true });
      queryClient.invalidateQueries('getPatients', { refetchActive: true }, { cancelRefetch: true });
      fetchCurrentUser();
      setIsLoading(false);
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
      setIsLoading(false);
    },
  });

  const ValidateSchema = Yup.object().shape({
    emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
  });

  const hookForm = useForm({
    mode: 'onChange',
    resolver: yupResolver(ValidateSchema),
  });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
  } = hookForm;
  const roleIds = roles?.data?.entities?.filter((role: any) => role.displayName === roleName.PATIENT)?.map((role: any) => role.id);

  const onSubmit = async (values: any) => {
    setIsLoading(true);
    try {
      const { data }: any = await getUsersByEmail(values.emailAddress);
      if (!data?.authId && data?.organizations?.[0]?.role?.displayName === roleName.PATIENT) {
        setIsReinvite(true);
        setOpenInviteModal(true);
        return;
      }

      const payload = { ...values, roleIds }
      mutaCreatePatient.mutate({ payload: payload });

    } catch (error) {
      onSave()
    }
  };

  const onSave = async () => {
    const values = handleTrimString(getValues())
    mutaCreatePatient.mutate({ payload: { ...values, roleIds } });
  };

  const toggleHandler = () => {
    setOpenModal(!openModal);
    reset();
  };

  useEffect(() => {
    if (isEdit) {
      const initData = {
        name: targetData.name,
      };
      reset(initData);
    } else {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(isLoading) {
    return <SpinnerComponent />;
  }

  return (
    <div ref={rootRef}>
      <Modal
        className="modal-organization-patient"
        show={openModal}
        size="md"
        popup={true}
        root={rootRef.current ?? undefined}
        onClose={toggleHandler}
        dismissible={true}
      >
        <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
        <Modal.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`${errors?.emailAddress && 'border-error'} pt-4 px-5 `}>
              <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.email')} *`} name="emailAddress" isHolderLabel={true} />
            </div>
            <div className="flex justify-center items-center pt-4 px-5 ">
              <GroupButton
                className="items-center justify-center pb-2"
                buttons={[
                  {
                    type: 'button',
                    text: t('modal.cancel'),
                    classType: 'white',
                    action: () => setOpenModal(false),
                  },
                  {
                    type: 'submit',
                    text: t('modal.save'),
                    classType: 'blue',
                    isLoading: mutaCreatePatient.isLoading,
                  },
                ]}
              />
            </div>

          </form>
        </Modal.Body>
        <PatientInviteModal openInviteModal={openInviteModal} setOpenInviteModal={setOpenInviteModal} isReinvite={isReinvite} onSubmit={onSave} />
      </Modal>
    </div>
  );
};
export default OrganizationPatientModal;
