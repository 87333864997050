import Avatar from 'components/GroupAvatar/Avatar';
import { IoIosClose } from 'react-icons/io';
import { HiChevronLeft, HiOutlineUsers, HiChatAlt2, HiOutlinePaperClip } from 'react-icons/hi';
import { IconContext } from 'react-icons';
import Button from 'components/button/button';
import Scrollbar from 'components/scrollbar/scrollbar';
import Svg from 'components/svg';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createConversations, getMessages, createMessage } from 'api/messagesApi';
import { useDispatch, useSelector } from 'react-redux';
import { conversationSelector, orgSelector, userSelector } from 'redux/selectors';
import { useMutation, useQuery } from 'react-query';
import { cloneDeep, concat, debounce, first, isEmpty, isNull, last, omitBy, orderBy, size, take } from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getOrganizationUsers } from 'api/userApi';
import LabelUser from 'components/labelUser';
import { routePaths, roleName } from 'utils/constants';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import './myMessages.scss';
import Empty from 'components/empty';
import { Spinner } from 'flowbite-react';
import Hightlight from 'components/hightlight';
import ConversationItem from 'components/conversationItem';
import { conversationAction } from 'redux/actions';
import SendFileModal from './sendFileModal';
import SpinnerComponent from '../../components/spinner';
import { useTranslation } from 'react-i18next';

const MyMessages = () => {
  const LIMIT_MESSAGE = 20;
  const [isFocus, setIsFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const { organizationId } = useSelector(orgSelector);
  const [targetPatents, setTargetPatents]: any = useState();
  const [targetConversations, setTargetConversations]: any = useState();
  const [paramsMessage, setParamsMessage]: any = useState({ toDate: moment().toISOString(), limit: LIMIT_MESSAGE });
  const [scrollParamsMessage, setScrollParamsMessage]: any = useState(null);
  const [seconds, setSeconds] = useState(0);
  const { userInfo } = useSelector(userSelector);
  const { conversations, messagesCollection } = useSelector(conversationSelector);
  const navigate = useNavigate();
  const params: any = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname
  const organization = userInfo?.organizations?.find((o: any) => o.id === organizationId && !!o.role);
  const isTherapist = [roleName.THERAPIST, roleName.ORGANIZATION_ADMIN].includes(organization?.role?.displayName);
  const isPatient = organization?.role?.displayName === roleName.PATIENT;
  const inputRef = useRef(null);
  const [openModalSendFile, setOpenModalSendFile] = useState(false)
  const [files, setFiles] = useState<any>([])
  const [t] = useTranslation()

  let organizationUsersParams: any = { limit: 0, searchQuery: searchValue.trim() }
  if (isPatient) {
    organizationUsersParams = { ...organizationUsersParams, roleNames: `${roleName.THERAPIST},${roleName.ORGANIZATION_ADMIN}` };
  } else if (isTherapist) {
    organizationUsersParams = { ...organizationUsersParams, roleNames: roleName.PATIENT };
  }
  const { data: dataPatients, isLoading: isLoadingPatients } = useQuery(['getPatients', searchValue, organizationId], () => getOrganizationUsers(organizationUsersParams), {
    staleTime: Infinity,
  });
  const [patients, setPatients] = useState(dataPatients?.data?.entities);

  const handleGetMessagesSuccess = (data: any) => {
    if (!params.id || params.id === 'new' || !data) return;
    const newMessagesCollection = cloneDeep(messagesCollection);
    const newMessages = newMessagesCollection[params.id] || {};
    if (isEmpty(newMessages.entities) || isEmpty(data?.data?.entities)) {
      newMessages.entities = concat(newMessages.entities || [], data?.data?.entities);
    } else {
      const firstMess: any = first(data?.data?.entities);
      const lastMess: any = last(newMessages.entities);
      if (firstMess.created < lastMess.created) {
        newMessages.entities = concat(newMessages.entities || [], data?.data?.entities);
      } else {
        newMessages.entities = concat(data?.data?.entities || [], newMessages.entities);
      }
    }
    newMessages.totalEntities = data?.data?.totalEntities;
    newMessagesCollection[params.id] = newMessages;
    newMessages.entities.forEach((elm: any) => {
      elm.isView = true;
    });

    let newConversations: any = cloneDeep(conversations);
    if (newConversations) {
      newConversations?.forEach((conversation: any) => {
        if (conversation.id === params.id) {
          const message: any = take(newMessages.entities, 6);
          conversation.messages = message;
          conversation.modified = message[0].modified;
        }
      });
      newConversations = orderBy(newConversations, conver => conver.modified, 'desc');
      dispatch(conversationAction.setConversations(newConversations));
    }
    dispatch(conversationAction.setMessagesCollection(newMessagesCollection));
  };

  const handleGetMessages = () => {
    if (params.id && params.id !== 'new') {
      return getMessages(omitBy({ conversationId: params.id, ...paramsMessage }, isNull));
    }
  };
  useQuery(['getMessages', params.id, paramsMessage], () => handleGetMessages(), {
    onSuccess: (data: any) => handleGetMessagesSuccess(data),
    staleTime: Infinity,
  });

  const handleGetMessagesScroll = () => {
    if (params.id && params.id !== 'new' && !!scrollParamsMessage) {
      return getMessages(omitBy({ conversationId: params.id, ...scrollParamsMessage }, isNull));
    }
  };
  const { data, isLoading: isLoadingMessages } = useQuery(['getMessagesScroll', scrollParamsMessage], () => handleGetMessagesScroll(), {
    onSuccess: (data: any) => handleGetMessagesSuccess(data),
    staleTime: Infinity,
  });

  const mutCreateConversations = useMutation('createConversation', {
    mutationFn: createConversations,
    onSuccess: ({ data }) => {
      const conversation: any = concat(
        data,
        conversations.filter((conver: any) => conver?.id !== data?.id),
      );
      dispatch(conversationAction.setConversations(conversation));
      if (isTherapist) {
        navigate(routePaths.THERAPIST_MESSAGES + '/' + data.id);

      }
      if (isPatient) {
        navigate(routePaths.PATIENT_MESSAGES + '/' + data.id);

      }
      handleSetParamsMessage(data.id);
      setTargetPatents(null)
      reset();
    },
  });

  const mutCreateMessage = useMutation('createMessage', {
    mutationFn: createMessage,
    onSuccess: () => {
      handleSetParamsMessage(params.id);
      reset();
    },
  });

  useEffect(() => {
    if (isPatient && url.includes(routePaths.THERAPIST_MESSAGES)){
      navigate(routePaths.PATIENT_MESSAGES);
    } 
    if (isTherapist && url.includes(routePaths.PATIENT_MESSAGES)){
      navigate(routePaths.THERAPIST_PATIENTS);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTherapist, isPatient]);

  useEffect(() => {
    const interval = setInterval(() => setSeconds(seconds => seconds + 1), 5000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleSetParamsMessage(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  useEffect(() => {
    if (conversations && params.id && params.id !== 'new') {
      const conversation = conversations.find((elm: any) => elm.id === params.id);
      if (conversation) {
        setTargetConversations(conversation);
      } else {
        navigate(routePaths.THERAPIST_MESSAGES);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    if (dataPatients?.data) setPatients(dataPatients?.data?.entities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPatients]);

  const handleOnChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
    setPatients(dataPatients?.data?.entities.filter((patient: any) => patient.id !== userInfo.id && getFullName(patient).includes(value)));
  };

  const handleOnClickPatient = (patient: any) => {
    setIsFocus(false);
    setTargetPatents(patient);
    setSearchValue('');
  };

  const handleOnClickConversation = (conversation: any) => {
    if (conversation.id === params.id) return;
    if (isTherapist) {
      navigate(routePaths.THERAPIST_MESSAGES + '/' + conversation.id);

    }
    if (isPatient) {
      navigate(routePaths.PATIENT_MESSAGES + '/' + conversation.id);
    }
    handleSetParamsMessage(conversation.id);
  };

  const handleSetParamsMessage = (id: any) => {
    const newMessages = messagesCollection[id];
    const payload: any = { toDate: moment().toISOString() };
    if (newMessages) {
      const firstMess: any = first(newMessages.entities);
      payload.formDate = moment(firstMess.created).add(1, 'milliseconds').toISOString();
      payload.limit = null;
    } else {
      payload.formDate = null;
      payload.limit = LIMIT_MESSAGE;
    }
    setParamsMessage(payload);
  };

  const handleOnClickNewConversation = () => {
    if (isTherapist) {
      navigate(routePaths.THERAPIST_MESSAGES + '/new');
    }

    if (isPatient) {
      navigate(routePaths.PATIENT_MESSAGES + '/new');
    }
    // navigate(routePaths.THERAPIST_MESSAGES + '/new');
  };

  const coverMessages = (messages: any) => {
    if (!messages) return [];
    const newMessages = [];
    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const messageNext = messages[i + 1];
      newMessages.push(message);
      if (!messageNext || moment(message.created).diff(moment(messageNext.created), 'minutes') > 30) {
        newMessages.push({ created: message.created });
      }
    }
    return newMessages;
  };

  const endScrollLoading = () => {
    const newMessages = messagesCollection[params.id];
    if (!newMessages) return;
    const lastMess: any = last(newMessages.entities);
    const payload: any = { toDate: moment(lastMess.created).toISOString(), limit: LIMIT_MESSAGE };
    setScrollParamsMessage(payload);
  };

  const onSubmit = ({ text, fileId }: any) => {
    if (params.id && params.id !== 'new') {
      if (!text && !fileId) return;
      mutCreateMessage.mutate({ conversationId: targetConversations.id, text });
    } else {
      if (!targetPatents || (!text && !fileId)) return;
      mutCreateConversations.mutate({ text, fileId, toUserId: targetPatents.id });
    }
  };

  const countHighlightMessage = () =>
    size(conversations.filter((convers: any) => convers.messages.find((mess: any) => !mess.isView && mess.userId !== userInfo.id)));

  const getFullName = (item: any) => `${item?.firstName || ''} ${item?.lastName || ''}`.trim() || item?.emailAddress;

  const { register, handleSubmit, reset }: any = useForm({ mode: 'onChange' });

  const handleChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value)
    setSearchValue(value); setPatients(dataPatients?.data?.entities.filter((patient: any) => patient.id !== userInfo.id && getFullName(patient).includes(value)));
  };
  const debouncedResults = useMemo(() => {
    return debounce(handleChange, 700);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  if (!conversations) return <></>;

  const handleUploadFile = (event: any) => {
    const files = event.target.files;
    setFiles([...files])
    setOpenModalSendFile(true)
    event.target.value = ''
  }


  return (
    <><form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-messages flex lg:m-8 border border-border relative">
        <div
          className={`w-full lg:w-[350px] bg-bg-hover border-r border-border overflow-hidden lg:!block lg:!static lg:!opacity-100 lg:!h-auto ${params.id ? 'absolute h-72 opacity-0 z-[0]' : 'block z-[1] transition-all duration-200'}`}
        >
          <div className="p-3.5 bg-gray-100 border-b border-border flex justify-between items-centers">
            <p className="text-lg text-gray-900 font-semibold flex items-center text-header">{t('Message')}</p>
            <Button color="primary" className="font-semibold" onClick={handleOnClickNewConversation}>
              {t('New Conversation')}
            </Button>
          </div>
          {!!size(conversations) && (
            <Scrollbar className="scroll-conversation" name="conversation">
              {conversations?.map((item: any) => (
                <ConversationItem key={item.id} item={item} onClick={handleOnClickConversation} />
              ))}
            </Scrollbar>
          )}
          {!size(conversations) && (
            <Empty
              icon={
                <IconContext.Provider value={{ color: "#D1D5DB", style: { strokeWidth: 1.5 } }}>
                  <HiOutlineUsers name="patients-icon" className="text-gray-300 w-auto h-[76px]" />
                </IconContext.Provider>
              }
              title={t('You currently do not have any conversations')}
              note={t('Type the name of the person you’d like to message on the ‘To:’ line to start a conversation')}
              className="border-0 mt-4 !bg-bg-hover" />
          )}
        </div>
        <div
          className={`w-full bg-white flex-col flex-1 lg:!flex lg:!static lg:!opacity-100 lg:!h-auto ${params.id ? '!flex z-[1] transition-all duration-200' : 'absolute h-72 opacity-0 z-[0]'}`}
        >
          {(!params.id || params?.id === 'new') && (
            <>
              <div className="h-14 w-full bg-gray-100 items-center justify-center relative text-center border-b border-border flex lg:hidden px-4 py-2.5">
                <button className="flex items-center text-primary absolute top-4 left-2" onClick={() => navigate(isTherapist ? routePaths.THERAPIST_MESSAGES : routePaths.PATIENT_MESSAGES)}>
                  <HiChevronLeft className="w-6 h-6" />
                  {countHighlightMessage() > 0 ? <Hightlight count={countHighlightMessage()} /> : <p className="text-sm font-semibold">{t('Back')}</p>}
                </button>
                <p className="text-base font-semibold text-gray-900">{t('New Conversation')}</p>
              </div>
              <div className="px-3.5 w-full flex items-center border-b border-border h-14">
                <p className="text-lg text-gray-900 font-semibold">{t('To')}:</p>
                {targetPatents && (
                  <div className="ml-2.5 pl-2 py-1 pr-1 bg-gray rounded flex items-center">
                    <p>{getFullName(targetPatents)}</p>
                    <button onClick={() => setTargetPatents(null)}>
                      <IoIosClose className="ml-1 text-gray-500 w-6 h-6" />
                    </button>
                  </div>
                )}
                <div className="relative w-full flex-1">
                  <input
                    className="w-full px-4 text-gray-500"
                    // value={searchValue}
                    onChange={debouncedResults}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setTimeout(() => setIsFocus(false), 200)} />
                  {!isEmpty(searchValue) && isFocus && (
                    <div className="absolute min-w-[300px] overflow-y-auto h-90 max-h-96 top-7 left-4 py-2 border border-border bg-white rounded-b-md shadow-[2px_2px_5px_0_rgba(0,0,0,0.05)]">
                      {patients?.map((patient: any) => (
                        <div className="px-4 py-2 hover:bg-light-blue" key={patient.id} onClick={() => handleOnClickPatient(patient)}>
                          <LabelUser item={patient} isCoppy={false} noInvite />
                        </div>
                      ))}

                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {params.id && params.id !== 'new' && targetConversations && (
            <div className="h-14 w-full bg-gray-100 items-center border-b border-border justify-between flex lg:hidden px-4 py-2.5">
              <button className="flex items-center text-primary" onClick={() => navigate(routePaths.THERAPIST_MESSAGES)}>
                <HiChevronLeft className="w-6 h-6" />
                {countHighlightMessage() > 0 ? <Hightlight count={countHighlightMessage()} /> : <p className="text-sm font-semibold"> {t('Back')}</p>}
              </button>
              <div className="text-center">
                <p className="text-base font-bold text-gray-900">{getFullName(targetConversations.toUser)}</p>
              </div>
              <Avatar item={targetConversations.toUser} />
            </div>
          )}
          {isLoadingMessages && (
            <div className="flex items-center justify-center">
              <Spinner size="sm" />
              <p className="text-sm text-primary mt-1 ml-2"> {t('Loading')}...</p>
            </div>
          )}
          <div className="flex-1 h-full overflow-hidden">
            {params.id && params.id !== 'new' && (
              <Scrollbar className="h-full" name="message" endScroll handleEndScroll={endScrollLoading}>
                <div className="flex flex-col-reverse px-8 py-4">
                  {coverMessages(messagesCollection[params.id]?.entities).map((message, index) => (
                    <div key={index + 1}>
                      {!message.userId && <MessageDay key={message.id} message={message} />}
                      {message.userId && message.userId === userInfo.id && <MessageCurrentUser key={message.id} message={message} />}
                      {message.userId && message.userId !== userInfo.id && <MessageFriendUser key={message.id} message={message} />}
                    </div>
                  ))}
                </div>
              </Scrollbar>
            )}
          </div>
          <div className="w-full flex shadow-[0_-2px_2px_-1px_rgba(0,0,0,0.10)]">
            {/* eslint-disable-next-line */}
            <input className="w-full px-4 text-gray-500" placeholder={`${t('Type a message')}...`} {...register('text')} />
            <div>
              <label
                htmlFor='upload-file'
              >
                <div className="py-3 px-4">
                  <IconContext.Provider value={{ color: "#6B7280", size: "24px" }}>
                    <HiOutlinePaperClip name="attachment" />
                  </IconContext.Provider>
                </div>
                <input multiple ref={inputRef} id='upload-file' type="file" className="hidden" accept="image/png, image/gif, image/jpeg, image/jpg" onChange={(e) => handleUploadFile(e)} />
              </label>
            </div>
            <button type="submit" className="bg-primary p-3">
              <Svg name="send" />
            </button>
          </div>
        </div>
      </div>
      {isLoadingPatients && <SpinnerComponent />}
    </form>
      <SendFileModal targetConversations={targetConversations} mutCreateMessage={mutCreateMessage} params={params} openModalSendFile={openModalSendFile} files={files} setFiles={setFiles} setOpenModalSendFile={setOpenModalSendFile} /></>
  );
};

const MessageFriendUser = ({ message }: any) => (
  <>
    {message.text &&
      <div className="flex justify-start mb-2.5">
        <div className="bg-gray-50 p-4 rounded-b-3xl rounded-tr-3xl max-w-[400px] text-sm text-black break-words">{message.text}</div>
      </div>}

    {size(message?.files) === 1 && <div className="flex justify-start mb-2.5">
      <img className="w-72 rounded-lg" src={message?.files[0]?.url} alt="" />
    </div>}
    {size(message?.files) === 2 &&
      <div className="flex justify-start mb-2.5">
        <div className="bg-gray-50 p-3 grid grid-cols-2 gap-2 auto-rows-min auto-cols-min rounded-t-3xl rounded-bl-3xl max-w-72  text-sm text-secondary break-words">
          {message?.files?.map((item: any) => (
            <img className='w-full h-16 rounded-lg' src={item?.url} alt="" />))}
        </div>
      </div>
    }
    {size(message?.files) > 2 &&
      <div className="flex justify-start mb-2.5">
        <div className="bg-gray-50 p-3 grid grid-cols-3 gap-2 auto-rows-min auto-cols-min rounded-t-3xl rounded-bl-3xl max-w-72  text-sm text-secondary break-words">
          {message?.files?.map((item: any) => (
            <img className='w-full h-16 rounded-lg' src={item?.url} alt="" />))}
        </div>
      </div>
    }
  </>
);

const MessageCurrentUser = ({ message }: any) => (
  <>
    {message.text &&
      <div className="flex justify-end mb-2.5">
        <div className="bg-light-blue p-4 rounded-t-3xl rounded-bl-3xl max-w-[400px] text-sm text-secondary break-words">{message.text}</div>
      </div>}

    {size(message?.files) === 1 &&
      <div className="flex justify-end mb-2.5">
        <img className="w-72 rounded-lg" src={message?.files[0]?.url} alt="" />
      </div>}

    {size(message?.files) === 2 &&
      <div className="flex justify-end mb-2.5">
        <div className="bg-light-blue p-3 grid grid-cols-2 gap-2 auto-rows-min auto-cols-min rounded-t-3xl rounded-bl-3xl max-w-72  text-sm text-secondary break-words">
          {message?.files?.map((item: any) => (
            <img className='w-full h-16 rounded-lg' src={item?.url} alt="" />))}
        </div>
      </div>
    }
    {size(message?.files) > 2 &&
      <div className="flex justify-end mb-2.5">
        <div className="bg-light-blue p-3 grid grid-cols-3 gap-2 auto-rows-min auto-cols-min rounded-t-3xl rounded-bl-3xl max-w-72  text-sm text-secondary break-words">
          {message?.files?.map((item: any) => (
            <img className='w-full h-16 rounded-lg' src={item?.url} alt="" />))}
        </div>
      </div>
    }
  </>
);

const MessageDay = ({ message }: any) => {
  const formatDateMessage = (date: any) => {
    if (!moment().startOf('days').diff(moment(date).startOf('days'), 'minutes')) {
      return moment(date).format('hh:mm A');
    } else if (!moment().startOf('week').diff(moment(date).startOf('week'), 'minutes')) {
      return moment(date).format('ddd, MMM DD, hh:mm A');
    } else {
      return moment(date).format('MMM DD, YYYY, hh:mm A');
    }
  };
  return (
    <div className="flex justify-center mb-2.5">
      <div className="p-2.5 font-medium text-xs text-gray-500">{formatDateMessage(message.created)}</div>
    </div>
  );
};

export default MyMessages;
