
import PaginateTable from 'components/table/paginate';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import { pageCount, apiStatus, roleName, routePaths } from 'utils/constants';
import { isEmpty, } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { useDispatch, useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { filterSelector, orgSelector, userSelector } from 'redux/selectors';
import { toast } from 'react-toastify';
import useUser from 'hooks/useUser';
import Actions from 'components/actions';
import LabelUser from 'components/labelUser';
import { deleteOrganizationUser, getOrganizationUsers } from 'api/userApi';
import SpinnerComponent from 'components/spinner';
import moment from 'moment';
import Avatar from 'components/GroupAvatar/Avatar';
import OrganizationPatientModal from './organizationPatientModal';
import Svg from 'components/svg';
import ListEmpty from 'components/empty/ListEmpty';
import AssignTherapistModal from './assignTherapistModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterAction } from 'redux/actions';

const OrganizationPatients = (props: any) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const [t] = useTranslation();
    const { organizationId } = useSelector(orgSelector);
    const { userInfo } = useSelector(userSelector);
    const location = useLocation();
    const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
    const [openModal, setOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [targetOrganization, setTartgetOrganization] = useState(initOrganization);
    const [assignedTherapist, setAssignedTherapist] = useState(null);
    const { fetchCurrentUser } = useUser();
    const navigate = useNavigate();

    const organization = userInfo?.organizations?.find((o: any) => o.id === organizationId && !!o.role);
    const isOrgAdmin = [roleName.THERAPIST, roleName.ORGANIZATION_ADMIN].includes(organization?.role?.displayName);

    const [seletedPatient, setSeletedPatient]: any = useState()
    const [openSetTherapistModal, setOpenSetTherapistModal] = useState(false)

    const fetchOrganizationUsers = async () => {
        const result = await getOrganizationUsers({
            page: currentPage,
            limit: pageCount,
            searchQuery: searchValue,
            roleNames: `${roleName.PATIENT}`
        })

        if (result) {
            setPatients(result?.data?.entities);
        }

        return result
    }

    const handleAddPatient = () => {
        setTartgetOrganization(initOrganization);
        setOpenModal(true);
        setIsEdit(false);
    };

    const { data, isLoading, isFetching } = useQuery(
        ['getPatients', currentPage, searchValue, organizationId],
        fetchOrganizationUsers,
        {
            onSuccess: (data: any) => {
                setTotalEntities(data.totalEntities);
            },
            onError: () => setPatients([]),
            staleTime: Infinity,
        },
    );

    const [patients, setPatients]: any = useState(data?.data?.entities);
    const [totalEntities, setTotalEntities] = useState(0);

    const handleSearch = (value: string) => {
        dispatch(filterAction.setFilter({key: location.pathname, searchValue: value}));
    };

    useEffect(() => {
        if (data !== undefined) {
            setPatients(data?.data?.entities);
            setTotalEntities(data?.data.totalEntities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        dispatch(filterAction.setFilter({key: location.pathname, searchValue}));
        // Need to fetch again the patients
        fetchOrganizationUsers()
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [organizationId])

    const renderTherapist = (item: any) => {
        const org = item?.organizations?.find((org: any) => org.id === organizationId);
        const orgName = `${org?.therapist?.firstName} ${org?.therapist?.lastName}`;
        
        return (
            org?.therapist && (
                <div className="flex">
                    <Avatar item={org?.therapist} />
                    <div className="ml-2 break-word dark:text-white font-semibold flex items-center">{orgName}</div>
                </div>
            )
        );
    };

    const handleRemoveMember = async (id: string) => {
        try {
            const data = await deleteOrganizationUser(id);
            if (data.status === apiStatus.NO_CONTENT) {
                const message: string = t('organizationPatients.deletedSuccess');
                toast.success(message);

                queryClient.invalidateQueries('getOrganizationPatients', { refetchActive: true }, { cancelRefetch: true });

                fetchCurrentUser();
            }
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }
    };

    const handleSetTherapist = (patient: any) => {
        const org = patient?.organizations?.find((org: any) => org.id === organizationId);
        if (org.therapist) {
            setAssignedTherapist(org.therapist)
        }
        let isMyPatient = false
        patients?.map((p: any) => {
            if (patient?.id === p.id) {
                const { organizations } = p
                organizations?.map((o: any) => {
                    const { therapist } = o
                    if (therapist?.id === userInfo.id) {
                        isMyPatient = true
                    }

                    return o
                })
            }

            return p
        })

        setSeletedPatient({ patient, isMyPatient })
        setOpenSetTherapistModal(true)
    }

    const handleViewDetail = (patientId: string) => {
        navigate(`${routePaths.ORG_PATIENTS}/${patientId}`);
    };

    const AddModal: any = () => {
        return (
            openModal && (
                <OrganizationPatientModal
                    headerTitle={t('organizationPatients.addPatient')}
                    openModal={openModal}
                    targetData={targetOrganization}
                    isEdit={isEdit}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    currentPage={currentPage}
                    setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value, }))}
                />
            )
        );
    };

    if (!isLoading && isEmpty(searchValue) && totalEntities === 0) {
        return (
            <div className="w-full sm:p-8 p-4">
                <ListEmpty
                    buttonName={t('organizationPatients.addPatient')} title={t('titlePatiensOfTherapistEmpty')}
                    note={t('notePatiensOfTherapistEmpty')}
                    handleAddClick={handleAddPatient}
                    icon={<Svg name='patient-empty' className='h-[84px] w-auto' />}
                />
                {AddModal()}
            </div>
        );
    }

    const AddSetTherapistModal: any = () => {
        return (
            openSetTherapistModal && (
                <AssignTherapistModal
                    isMyPatient={seletedPatient?.isMyPatient}
                    onSuccess={() => {
                        setSeletedPatient(undefined)
                        setOpenSetTherapistModal(false)

                        fetchOrganizationUsers()
                    }}
                    openModal={openSetTherapistModal}
                    patientId={seletedPatient?.patient.id || ''}
                    setOpenModal={setOpenSetTherapistModal}
                    assignedTherapist={assignedTherapist}
                />
            )
        )
    }

    return (
        <div className="w-full h-full px-[15px] md:px-[30px] lg:py-[10px]">
            <div className='md:py-5'>
                <ActionTable
                    placeholderSearch={t('Search')}
                    buttonName={t('organizationPatients.addPatient')}
                    handleAddClick={handleAddPatient}
                    handleSearch={handleSearch}
                    searchValue={searchValue}
                />
            </div>
            {totalEntities === 0 && !isLoading && <NoResult />}
            {totalEntities > 0 && (
                <>
                    <div className="overflow-x-auto mb-2 list-table">
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
                            <thead className="group/head text-xs text-gray-700 dark:text-gray-400 normal-case border-b-2 text-header">
                                <tr className="w-full">
                                    <th scope="col" className="font-medium py-2 px-4 text-black">
                                        {t('organizationPatients.fullName')}
                                    </th>
                                    <th scope="col" className="hidden-mobile-tablet font-medium py-2 px-4 text-black">
                                        {t('organizationPatients.dateOfBirth')}

                                    </th>
                                    <th scope="col" className="hidden-mobile font-medium py-2 px-4 text-black">
                                        {t('organizationPatients.therapist')}
                                    </th>
                                    <th scope="col" className="font-medium py-2 w-6" />
                                </tr>
                            </thead>
                            <tbody>
                                {patients?.map((item: any) => {
                                    return (
                                        <tr
                                            onClick={() => handleViewDetail(item?.id)}
                                            key={`${item.id}-list-organization`}
                                            className="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-hover-5 dark:hover:bg-gray-600 cursor-pointer"
                                        >
                                            <td className="py-2 px-3 flex flex-row itemx-center text-primary-name font-medium text-gray-900 dark:text-white cursor-pointer">
                                                <LabelUser item={item} />
                                            </td>
                                            <td className="hidden-mobile-tablet text-medium text-on-primary-container text-black py-2.5 px-4">
                                                {item?.dateOfBirth ? moment(item?.dateOfBirth).format('ll') : ''}
                                            </td>
                                            <td className="hidden-mobile text-medium text-on-primary-container text-black py-2.5 px-4">
                                                {renderTherapist(item)}                                            </td>
                                            <td className="py-2.5 " onClick={(e) => e.stopPropagation()}>
                                                <Actions>
                                                    <Actions.Item
                                                        action={() => handleViewDetail(item?.id)} 
                                                        label={t('action.overview')}
                                                    />
                                                    {
                                                        isOrgAdmin && <Actions.Item action={() => handleSetTherapist(item)} label={t('organizationPatients.setTherapist')} />
                                                    }
                                                    <Actions.Item action={() => handleRemoveMember(item?.id)} label={t('action.removeMember')} />
                                                </Actions>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <PaginateTable
                        setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value, }))}
                        currentPage={currentPage}
                        totalEntities={totalEntities}
                    />
                </>
            )}
            {(isLoading || isFetching) && <SpinnerComponent />}
            {AddModal()}
            {AddSetTherapistModal()}
        </div>
    );
};

export default OrganizationPatients;
