import { useDispatch, useSelector } from 'react-redux';
import { orgSelector, userSelector } from 'redux/selectors';
import { checkPermission, checkRole, getNavbarUrl, messageErrors } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { pageType, permissionsOrg, permissionsSite, routePaths, roleName } from 'utils/constants';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import { useEffect, useState, useRef } from 'react';
import './userProfile.scss';
import { size } from 'lodash';
import { userAction } from '../redux/actions';
import Avatar from './GroupAvatar/Avatar';
import { useMutation } from 'react-query';
import SpinnerComponent from './spinner';
import { chargeSubscription, getCustomerPortal } from '../api/stripeApi';
import { toast } from 'react-toastify';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { fetchCurrentUser } = useUser();
  const ref: any = useRef();
  const { organizationId } = useSelector(orgSelector);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { userInfo, isShowSubcribe } = useSelector(userSelector);
  const { userRole, firstName, lastName, emailAddress } = userInfo;
  const { handleLogout } = useUser();
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const checkOrgAdminRole = checkRole(userInfo, [roleName.ORGANIZATION_ADMIN], organizationId);
  const checkAdminRole = userInfo?.roles.find((role: any) => role.displayName === roleName.SITE_ADMIN);
  const muHandleLogout = useMutation('handleLogout', { mutationFn: handleLogout });

  const mutateCustomerPortal = useMutation('getCustomerPortal', {
    mutationFn: getCustomerPortal,
    onSuccess: ({ data }) => {
      window.open(data?.url, '_blank', 'noopener,noreferrer');
    },
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSubscription = async () => {
    try {
      const result = await chargeSubscription();
      if (result.status === 201 && result?.data?.url) window.location.replace(result.data.url);
      if (result.status === 201) {
        navigate(routePaths.DASHBOARD_PAGE);
        fetchCurrentUser();
      } else {
      }
    } catch (e) {}
  };

  const handleMySubscription = () => {
    if (checkOrgAdminRole) {
      if (isShowSubcribe) {
        handleSubscription();
      } else {
        mutateCustomerPortal.mutate();
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowDropDown(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isShowDropDown]);

  const handleOpenModalProfile = () => {
    const isOpenModal: any = true;
    dispatch(userAction.setOpenProfileModal(isOpenModal));
  };
  return (
    <div className="user-profile">
      <div
        className={`relative user-dropdown flex items-center`}
        ref={ref}
        onClick={() => setIsShowDropDown(!isShowDropDown)}
        data-testid={`test-user-profile`}
      >
        <div className="flex justify-between items-center w-full">
          <Avatar item={userInfo} />{' '}
          <div className="pl-4 text-white text-xs font-normal uppercase">
            {userInfo.firstName} {userInfo.lastName}
          </div>
        </div>
        <div className={`${!isShowDropDown && 'hidden'} dropdown-item text-sm`}>
          <div className="flex p-3">
            <div>
              <Avatar item={userInfo} />
            </div>
            <div className="ml-3 flex flex-col justify-center">
              <span className="block capitalize font-medium">{`${firstName} ${lastName}`}</span>
              <span className="block truncate text-xs text-gray-500 text-right">{emailAddress}</span>
            </div>
          </div>
          {userInfo.isActive && (
            <>
              <hr />
              <div
                className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer hover:bg-gray-100 cursor-pointer"
                onClick={handleOpenModalProfile}
              >
                {t('navbar.profile')}
              </div>
              {checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId) &&
                size(userInfo.organizations) > 0 &&
                (checkOrgAdminRole || checkAdminRole) && (
                  <div
                    className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                    onClick={() => navigate(getNavbarUrl(userInfo, pageType.ORGANIZATION, organizationId))}
                  >
                    {t('navbar.organizationSettings')}
                  </div>
                )}
              {checkPermission(userInfo, pageType.ORGANIZATION, permissionsOrg, organizationId) &&
                size(userInfo.organizations) > 0 &&
                checkOrgAdminRole && (
                  <div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer" onClick={handleMySubscription}>
                    {t('navbar.mySubscriptions')}
                  </div>
                )}
              {checkPermission(userInfo, pageType.SITE, permissionsSite, organizationId) && checkAdminRole && (
                <div
                  className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate(getNavbarUrl(userInfo, pageType.SITE, organizationId))}
                >
                  {t('navbar.admin')}
                </div>
              )}
              {userRole === roleName.INTEGRATOR && (
                <>
                  <hr />
                  <div className="px-4 py-3 text-gray-900 hover:bg-gray-100 cursor-pointer" onClick={() => navigate(routePaths.MY_TOKENS)}>
                    {t('navbar.integrator')}
                  </div>
                </>
              )}
            </>
          )}

          <hr />
          <div className="px-4 py-2 text-gray-900 hover:bg-gray-100 cursor-pointer" onClick={() => muHandleLogout.mutate()}>
            <span className="text-red-500">{t('logout')}</span>
          </div>
        </div>
      </div>
      {muHandleLogout.isLoading && <SpinnerComponent />}
    </div>
  );
};
export default UserProfile;
