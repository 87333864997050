/* eslint-disable react/jsx-props-no-spreading */

import ActionTable from 'components/table/actionTable';
import PaginateTable from 'components/table/paginate';
import { apiStatus, permissionKeys, sortByTypeType } from 'utils/constants';
import { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import NoResult from 'components/commonComponent/noResult';
import './patientList.scss';
import SpinnerComponent from 'components/spinner';
import Table from 'components/table/table';
import { useLocation, useNavigate } from 'react-router-dom';
import Actions from 'components/actions';
import { changeProgramStatus, deleteProgram, getPatientPrograms, getProgramById } from 'api/programApi';
import CheckboxCustom from 'components/checkbox';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import '../../../styles/styles.scss'
import './programs.scss'
import { HiOutlineChevronDown, HiOutlineTrash, HiOutlinePencil, HiOutlinePrinter } from 'react-icons/hi';
import Sort from 'components/table/sort';
import { checkPermission, messageErrors } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { filterSelector, orgSelector, userSelector } from 'redux/selectors';
import AddProgramModal from './addProgramModal';
import ListEmpty from 'components/empty/ListEmpty';
import Svg from 'components/svg';
import { useReactToPrint } from 'react-to-print';
import ProgramPDF from './pdf/programPDF';
import ConfirmDeleteModal from 'components/modal/confirmDeleteModal';
import { filterAction } from 'redux/actions';

const Programs = (props: any) => {
    const dispatch = useDispatch();
    const [t] = useTranslation();
    const navigate = useNavigate();
    const { userInfo } = useSelector(userSelector);
    const queryClient = useQueryClient();
    const location = useLocation();
    const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
    const [totalEntities, setTotalEntities] = useState(0);
    const [programs, setPrograms] = useState([]);
    const [includeInactive, setIncludeInactive] = useState(false)
    const params: any = useParams();
    const [sortBy, setSortBy] = useState('');
    const [sortByType, setSortByType] = useState('');
    const [allPrograms, setAllPrograms] = useState(0)
    const { WRITE_PROGRAM } = permissionKeys;
    const { organizationId } = useSelector(orgSelector);
    const isWriteProgram = checkPermission(userInfo, props.type, [WRITE_PROGRAM], organizationId);
    const [openModal, setOpenModal] = useState(false);
    const pdfRef: any = useRef();
    const [selectedProgram, setSelectedProgram] = useState(null)
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false)
    const [deleteProgramId, setDeleteProgramId] = useState('')

    const renderColor = (isSelected: any, isFocused: any) => {
        if ((isSelected && isFocused) || isSelected) {
            return 'rgba(227, 249, 253, 1)';
        } else if (isFocused) return 'rgba(132, 116, 106, 0.1)';
        return null;
    };

    const colourStyles = {
        option: (styles: any, { isFocused, isSelected }: any) => {
            return {
                ...styles,
                color: 'black',
                backgroundColor: renderColor(isSelected, isFocused),
            };
        },
    };

    const DropdownIndicator = (props: any) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <HiOutlineChevronDown className="h-4 w-4 text-black" />
                </components.DropdownIndicator>
            )
        );
    };

    const { data: allProgramData, isLoading: isLoadingAllPrograms } = useQuery(
        ['getAllPatientPrograms', params.id],
        () =>
            getPatientPrograms({
                page: 0,
                limit: 0,
                patientId: params.id,
            }),
        {
            onError: (error) => {
                setTotalEntities(0)
                setPrograms([])
                setAllPrograms(0)
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
            staleTime: Infinity,
        },
    );

    const { data, isLoading } = useQuery(
        ['getPatientPrograms', currentPage, searchValue, params.id, includeInactive, sortBy, sortByType],
        () =>
            getPatientPrograms({
                page: currentPage,
                limit: 5,
                searchQuery: searchValue,
                patientId: params.id,
                includeInactive,
                sortBy,
                sortByType
            }),
        {
            onSuccess: (data: any) => {
                setTotalEntities(data.totalEntities);
            },
            onError: (error) => {
                setTotalEntities(0)
                setPrograms([])
                setAllPrograms(0)
                const message: string = messageErrors(error, t);
                toast.error(message);
            },
            staleTime: Infinity,
        },
    );

    const handleHeaderSort = (field: string) => {
        setSortBy(field);
        let type = '';
        if (field !== sortBy) {
            type = sortByTypeType.ASC
        } else if (sortByType === sortByTypeType.ASC) {
            type = sortByTypeType.DESC;
        } else {
            setSortBy(type);
        }
        setSortByType(type)
    }

    const handleChangeStatus = (data: any) => {
        return changeProgramStatus(data)
    };

    const mutationUpdateProgramStatus = useMutation('changr-status', {
        mutationFn: handleChangeStatus,
        onSuccess: () => {
            queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
        },
        onError: async (error: any) => {
            const message: string = `${error.response.data.errors?.[0].detail}`;
            toast.error(message);
        },
    });
    const handleSearch = (value: string) => dispatch(filterAction.setFilter({key: location.pathname, searchValue: value}));

    useEffect(() => {
        if (data !== undefined) {
            setPrograms(data?.data?.entities);
            setTotalEntities(data?.data.totalEntities);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setTotalEntities(data?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    useEffect(() => {
        if (allProgramData) {
            setAllPrograms(allProgramData?.data?.entities?.length)
        }
    }, [allProgramData])

    const handleAddProgram = () => {
        setOpenModal(true)
    }
    const handleCheckedStatus = (e: any) => {
        if (e.target.checked) {
            setIncludeInactive(true)

        } else setIncludeInactive(false)
        dispatch(filterAction.setFilter({key: location.pathname, searchValue}))
    }

    const handleRemoveProgram = async () => {
        try {

            const data = await deleteProgram(deleteProgramId);
            if (data.status === apiStatus.SUCCESS) {
                const message: string = t('deletedProgram');
                toast.success(message);
                queryClient.invalidateQueries('getPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getAllPatientPrograms', { refetchActive: true }, { cancelRefetch: true });
            }
            setOpenConfirmDeleteModal(false)
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }
    }
    const statusCheckbox = () => {
        return (
            <CheckboxCustom id="showInactive" label={t('showInactivePrograms')} handleChange={(e: any) => handleCheckedStatus(e)} />
        )
    }

    const handleChangeProgramStatus = (item: any, e: any) => {
        const payload = { status: e.value }
        mutationUpdateProgramStatus.mutate({ payload, id: item?.id });
    }

    const reactToPrint = useReactToPrint({ content: () => pdfRef.current });
    const handlePrint = async (item: any) => {
        try {
            const program: any = await getProgramById(item.id)
            if (program) {
                setSelectedProgram(program.data)
                setTimeout(() => {
                    reactToPrint();
                }, 200);
            }
        }
        catch (error) {
            console.log("error");
        }
    };

    const AddProgram: any = () => {
        return (
            openModal && (
                <AddProgramModal
                    headerTitle={t('addProgram')}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value}))}
                    currentPage={currentPage}
                    therapistId={params.id}
                />
            )
        );
    };

    if (!isLoading && isEmpty(searchValue) && totalEntities === 0 && allPrograms === 0 && isWriteProgram) {
        return (
            <div className="w-full py-2 px-6 ">
                <ListEmpty
                    buttonName={t('addProgram')}
                    handleAddClick={handleAddProgram}
                    note={t('noteProgramEmpty')}
                    title={t('titleProgramEmpty')}
                    icon={<Svg name='program-empty' className='h-[84px] w-auto' />}
                />
                <AddProgram />
            </div>
        );
    }

    const handleEditProgram = (programId: string) => {
        navigate(`${location.pathname}/program-detail?programId=${programId}`, {
            state: {
                //...values
            }
        }
        );
    }

    return (
        <div className='programs h-full flex flex-col'>
            {allPrograms > 0 && (
                <div className="md:mx-6 md:mt-2 mx-4 justify-between grow flex flex-col">
                    <div className='pb-5'>
                        <ActionTable 
                            placeholderSearch={t('Search')} 
                            buttonName={t('addProgram')} 
                            handleSearch={handleSearch} 
                            handleAddClick={handleAddProgram} 
                            statusCheckbox={statusCheckbox()}
                            searchValue={searchValue}
                        />
                    </div>
                    {totalEntities === 0 && !isLoading && <div className=" mb-2 grow"><NoResult /> </div>}
                    {totalEntities > 0 && programs.length > 0 && (
                        <>
                            <div className=" mb-2 grow">
                                <Table>
                                    <Table.Head className="text-black">
                                        <Table.HeadCell className="cursor-pointer" onClick={() => handleHeaderSort('name')}>
                                            <div className="flex items-center">
                                                {t('programName')}
                                                <Sort check={sortBy === 'name'} sortByType={sortByType} />
                                            </div>
                                        </Table.HeadCell>
                                        <Table.HeadCell scope="col" className="py-2 w-6" />
                                    </Table.Head>
                                    <Table.Body>
                                        {programs.map((item: any) => (
                                            <Table.Row key={`row-${item.id}`} className="bg-white text-base hover:border-b text-on-primary-container">
                                                <Table.Cell className="font-medium items-center text-black h-full cursor-pointer"
                                                    onClick={() => handleEditProgram(item?.id)}
                                                >
                                                    {item.name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Actions>
                                                        <Actions.Item
                                                            show
                                                            action={() => handleEditProgram(item?.id)}
                                                            icon={<HiOutlinePencil className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                        />
                                                        <Actions.Item
                                                            show
                                                            action={() => {
                                                                setOpenConfirmDeleteModal(true)
                                                                setDeleteProgramId(item?.id)
                                                            }}
                                                            icon={<HiOutlineTrash className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                        />
                                                        <Actions.Item
                                                            show
                                                            action={() => handlePrint(item)}
                                                            icon={<HiOutlinePrinter className="w-5 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                        />
                                                    </Actions>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                            <PaginateTable 
                                setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value}))}
                                currentPage={currentPage} 
                                totalEntities={totalEntities} 
                                pageCount={5} 
                            />
                        </>
                    )}
                    <AddProgram />
                </div>
            )}
            {(isLoading || mutationUpdateProgramStatus.isLoading) && <SpinnerComponent />}
            <ProgramPDF pdfRef={pdfRef} program={selectedProgram} />
            {openConfirmDeleteModal && <ConfirmDeleteModal openModal={openConfirmDeleteModal} setOpenModal={setOpenConfirmDeleteModal} handleRemove={handleRemoveProgram} />}
        </div >
    )
}
export default Programs