import { HiOutlineDuplicate } from 'react-icons/hi';
import { deleteRole, getPermissions, getRoles } from 'api/rolesApi';
import NoResult from 'components/commonComponent/noResult';
import SpinnerComponent from 'components/spinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { exportFileName, defaultpageCount, permissionKeys, sortByTypeType } from 'utils/constants';
import { roleType } from 'utils/proptypes';
import { checkPermission, copyToClipboard, exportToFile } from 'utils/utils';
import RoleModal from 'components/modal/roleModal/roleModal';
import PaginateTable from 'components/table/paginate';
import './roleManagement.scss';
import { useDispatch, useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { filterSelector, orgSelector, userSelector } from 'redux/selectors';
import '../styles/styles.scss';
import { toast } from 'react-toastify';
import { size } from 'lodash';
import Actions from '../components/actions';
import Sort from 'components/table/sort';
import Table from 'components/table/table';
import { filterAction } from 'redux/actions';
import { useLocation } from 'react-router-dom';

const initRole = {
  id: '',
  displayName: '',
  type: '',
  permissions: [],
};

const RoleManagementPage = (props: any) => {
  const dispatch = useDispatch();
  const { WRITE_ROLE } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const { userInfo } = useSelector(userSelector);
  const location = useLocation();
  const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
  const [totalEntities, setTotalEntities] = useState(0);
  const [roles, setRoles] = useState([]);
  const [targetRole, setTargetRole] = useState(initRole);
  const [openModal, setOpenModal] = useState(false);
  const [permissions, setPermission] = useState([]);
  const queryClient = useQueryClient();

  const [sortBy, setSortBy] = useState('');
  const [sortByType, setSortByType] = useState('');
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ROLE], organizationId);
  const [pageCount, setPageCount] = useState(defaultpageCount);

  const getAllPermission = useMutation('getAllPermission', {
    mutationFn: getPermissions,
    onSuccess: ({ data }) => setPermission(data),
    onError: () => setPermission([]),
  });

  const { data, isLoading } = useQuery(
    ['getRoles', currentPage, searchValue, sortBy, sortByType, pageCount],
    () => getRoles({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data?.data.totalEntities);
      },
      onError: () => setRoles([]),
      staleTime: Infinity,
    },
  );

  const handleSearch = (value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue: value}));

  useEffect(() => {
    getAllPermission.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      setRoles(data?.data?.entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleClickEdit = (item: any) => {
    setTargetRole(item);
    setOpenModal(true);
  };

  const handleClickAddRole = () => {
    setTargetRole(initRole);
    setOpenModal(true);
  };

  const handleCloseModal = (isCloseModal: any) => {
    setOpenModal(isCloseModal);
    if (!isCloseModal) {
      setTargetRole(initRole);
    }
  };

  const handleClickDelete = async (id: string) => {
    try {
      const response = await deleteRole(id);
      if (response.status === 200) {
        queryClient.invalidateQueries('getRoles', { refetchActive: true }, { cancelRefetch: true });
        const message: string = t('roleManagementPage.deleteSuccessfully')
        toast.success(message)
      }
    } catch (error: any) {
      const message: string = `${error.response.data.errors?.[0].detail}`;
      toast.error(message);
    }
  };

  const handleHeaderSort = (field: string) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      setSortBy(type);
    }
    setSortByType(type)
  }

  const handleOnClickExport = () => {
    const exportedData = roles.map((item: any) => ({
      Name: item.displayName,
      Type: item.type,
      Permissions: item?.permissions
        ?.map((itemPremission: any) => {
          return itemPremission;
        })
        .join(', '),
    }));
    exportToFile(exportedData, exportFileName.ROLE);
  };

  return (
    <div className="w-full role-management">
      <div className="py-5">
        <ActionTable
          placeholderSearch={t('roleManagementPage.placeholderSearch')}
          buttonName={t('roleManagementPage.buttonAddRoleLabel')}
          handleAddClick={checkPermission(userInfo, props.type, [WRITE_ROLE], organizationId) && handleClickAddRole}
          handleSearch={handleSearch}
          searchValue={searchValue}
        />
      </div>
      {totalEntities === 0 && !isLoading && <NoResult />}
      {totalEntities > 0 && (
        <div className="bg-white">
          <Table className="border-b table-auto">
            <Table.Head className="text-sm text-gray-600 font-semibold border-b-2 border-[#f9f5f5]">
              <Table.HeadCell className="cursor-pointer normal-case bg-white px-[15px]" onClick={() => handleHeaderSort('displayName')}>
                <div className="text-black font-medium text-xs flex items-center">
                  {t('roleManagementPage.Table.ColumnColName')}
                  <Sort check={sortBy === 'displayName'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="hidden-mobile font-semibold" onClick={() => handleHeaderSort('type')}>
                <div className="text-black font-medium text-xs flex items-center">
                  {t('roleManagementPage.Table.ColumnColType')}
                  <Sort check={sortBy === 'type'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="hidden-mobile font-semibold " onClick={() => handleHeaderSort('countPermission')}>
                <div className="text-black font-medium text-xs flex items-center">
                  {t('roleManagementPage.Table.countPermission')}
                  <Sort check={sortBy === 'countPermission'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="hidden-mobile-tablet font-semibold " onClick={() => handleHeaderSort('description')}>
                <div className="text-black font-medium text-xs flex items-center">
                  {t('roleManagementPage.Table.description')}
                  <Sort check={sortBy === 'description'} sortByType={sortByType} />
                </div>
              </Table.HeadCell>
              <Table.HeadCell className="w-6">{''}</Table.HeadCell>
            </Table.Head>
            <Table.Body>
              {roles?.map((item: roleType, index) => (
                <Table.Row key={`row-${index + 1}`} className="bg-white text-base hover:bg-gray-100 border-b">
                  <Table.Cell className="text-gray-900 font-semibold py-2.5">
                    <div className="flex flex-row items-center">
                      <div>{`${item.displayName || ''}`}</div>
                      <div className="copy-icon pl-3 cursor-pointer">
                        <HiOutlineDuplicate size={25} onClick={() => copyToClipboard(item.displayName, t('roleManagementPage.copiedName'))} />
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell className="text-gray-900 py-2.5 hidden-mobile">{item.type}</Table.Cell>
                  <Table.Cell className="text-gray-900 py-2.5 hidden-mobile">{size(item.permissions)}</Table.Cell>
                  <Table.Cell className="text-gray-900 py-2.5 hidden-mobile-tablet whitespace-pre-line">{item.description}</Table.Cell>
                  <Table.Cell className="py-2.5">
                    {isWriteOrg && (
                      <Actions>
                        <Actions.Item action={() => handleClickEdit(item)} label={t('edit')} />
                        <Actions.Item action={() => handleClickDelete(item.id)} label={t('roleManagementPage.buttonDeleteRoleLabel')} />
                      </Actions>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          {isLoading && <SpinnerComponent />}
          <div className='py-8'>
            <PaginateTable
              setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value}))}
              currentPage={currentPage}
              totalEntities={totalEntities}
              isLoadingTable={isLoading}
              exportHandler={handleOnClickExport}
              pageCount={pageCount}
              setPageCount={setPageCount}
            />
          </div>
        </div>
      )}
      {openModal && (
        <RoleModal
          headerTitle={targetRole.id.length > 0 ? t('roleManagementPage.RoleModal.ModalEditTitle') : t('roleManagementPage.RoleModal.ModalAddTitle')}
          openModal={openModal}
          targetData={targetRole}
          isEdit={targetRole.id.length > 0}
          allAvailablePermission={permissions || []}
          handleCloseModal={(isClose: any) => handleCloseModal(isClose)}
          permissions={permissions}
        />
      )}
    </div>
  );
};

export default RoleManagementPage;
