/* eslint-disable react/jsx-props-no-spreading */

import { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { apiStatus, pageType, roleName, routePaths } from 'utils/constants';
import { toast } from 'react-toastify';
import './organizationTherapistDetail.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner, Tabs, TabsRef } from 'flowbite-react';
import ButtonBack from 'components/button/buttonBack';
import DetailTab from 'pages/userInfo';
import Banner from 'components/banner/banner';
import { messageErrors } from 'utils/utils';
import { getOrganizationUsers, getUserById, unassignPatient } from 'api/userApi';
import { getRoles } from 'api/rolesApi';
import { useSelector } from 'react-redux';
import { orgSelector } from 'redux/selectors';
import ActionTable from 'components/table/actionTable';
import { isEmpty } from 'lodash';
import LabelUser from 'components/labelUser';
import moment from 'moment';
import PaginateTable from 'components/table/paginate';
import NoResult from 'components/commonComponent/noResult';
import AssignPatientModal from './assignPatientModal';
import Actions from 'components/actions';
import { HiOutlineTrash } from 'react-icons/hi';
import ListEmpty from 'components/empty/ListEmpty';
import Svg from 'components/svg';

const TherapistDetail = (props: any) => {
    const [t] = useTranslation();
    const [therapist, setTherapist]: any = useState();
    const params: any = useParams();
    const tabsRef = useRef<TabsRef>(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [patientList, setPatientList] = useState([]);
    const { organizationId } = useSelector(orgSelector);
    const [totalEntities, setTotalEntities] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const queryClient = useQueryClient()
    const [isLoaded, setIsLoaded] = useState(false)
    const navigate = useNavigate()
    const { data } = useQuery(['getTherapist', params?.id], () => getUserById(params?.id), {
        retry: false,
        staleTime: Infinity,
        onError: error => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        },
    });

    const { data: therapistPatients, isLoading, isFetching } = useQuery(
        ['getOrganizationTherapistPatients', currentPage, searchValue, organizationId, params.id],
        () =>
            getOrganizationUsers({
                page: currentPage,
                limit: 5,
                searchQuery: searchValue,
                roleNames: roleName.PATIENT,
                therapistId: params.id
            }),
        {
            staleTime: Infinity,
        },
    );
    const { data: roles } = useQuery(['getOrganizationRoles'], () => getRoles({ page: 0, limit: 0 }), {
        staleTime: Infinity,
    });

    const roleOptions: any =
        roles?.data?.entities
            ?.filter((role: any) => role.displayName === roleName.THERAPIST || role.displayName === roleName.ORGANIZATION_ADMIN ||  role.displayName === roleName.PATIENT )

    const handleAssignPatient = () => {
        setOpenModal(true)
    }

    const handleRemovePatient = async (patientId: string) => {
        try {
            const payload = {
                patientId, therapistId: params.id
            }
            const data = await unassignPatient(payload);
            if (data.status === apiStatus.NO_CONTENT) {
                const message: string = t('unassignedPatient');
                toast.success(message);
                queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getOrganizationPatients', { refetchActive: true }, { cancelRefetch: true });
                queryClient.invalidateQueries('getOrganizationTherapistPatients', { refetchActive: true }, { cancelRefetch: true });
            }
        } catch (e) {
            const message: string = t('generalErrors');
            toast.error(message);
        }

    }

    const handleSearch = (value: string) => {
        setIsLoaded(false)
        setSearchValue(value);
        setCurrentPage(0);
    };

    useEffect(() => {
        if (data?.data) {
            setTherapist(data?.data);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (therapistPatients?.data) {
            setPatientList(therapistPatients?.data?.entities)
            setTotalEntities(therapistPatients?.data.totalEntities);
            setIsLoaded(true)
        }
    }, [therapistPatients?.data, searchValue])

    useEffect(() => {
        // setIsLoaded(false)
        setCurrentPage(0)
        setTotalEntities(therapistPatients?.data.totalEntities);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const AssignPatient: any = () => {
        return (
            openModal && (
                <AssignPatientModal
                    headerTitle={t('organizationTherapists.assignPatient')}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    queryClient={queryClient}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    therapistId={params.id}
                    roles={roles}
                />
            )
        );
    };

    const handleBack = () => {
        navigate(`${routePaths.ORG_THERAPISTS}`);
    }
    
    const PatientListEmpty = () => {
        return (
            <div className="w-full px-6 py-2">
                <ListEmpty
                    buttonName={t('organizationTherapists.assignPatient')}
                    title={t('titlePatiensOfTherapistEmpty')}
                    note={t('notePatiensOfTherapistEmpty')}
                    handleAddClick={handleAssignPatient}
                    icon={<Svg name='patient-empty' className='h-[84px] w-auto' />}
                />
            </div>
        );
    }

    return (
        <div className="flex flex-col therapist-profile w-full h-full">
            {therapist && (
                <>
                    <Banner targetData={therapist} isWrite={false} labelId="Therapist ID" handleBack={handleBack} />
                    <div className="therapist-infor h-full flex">
                        {/* eslint-disable-next-line  */}
                        <Tabs ref={tabsRef} aria-label="Tabs with underline" variant="underline" className="tab-button w-full">
                            <Tabs.Item active title={t('DETAILS')}>
                                <div className="mb-4">
                                    <DetailTab type={pageType.ORGANIZATION} targetData={therapist} isWrite={true} roleData={roleOptions} messageUpdate={t('updatedTherapist')} isTherapistDetailSite/>
                                </div>
                            </Tabs.Item>
                            <Tabs.Item active title={t('PATIENTS')}>
                                <div className="w-full h-full">
                                    {!isLoading && !isFetching && isLoaded && searchValue === '' && totalEntities === 0 && patientList.length === 0 ? <PatientListEmpty /> : (
                                        <div className="w-full h-full sm:px-8 px-4 grow flex flex-col">
                                            <div className='grow'>
                                                <div className='pt-2 pb-5'>
                                                    <ActionTable
                                                        placeholderSearch={t('Search')}
                                                        buttonName={t('organizationTherapists.assignPatient')}
                                                        handleAddClick={handleAssignPatient}
                                                        handleSearch={handleSearch}
                                                    />
                                                </div>
                                                {!isLoading && totalEntities === 0 && isEmpty(patientList) && <NoResult />}
                                                {!isEmpty(patientList) && totalEntities > 0 && (
                                                    <>
                                                        <div className="mb-2">
                                                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 pb-32">
                                                                <thead className="text-xs border-b-2 text-black dark:bg-gray-700 dark:text-gray-400 text-header">
                                                                    <tr className="w-full">
                                                                        <th scope="col" className="font-medium py-3 px-4">
                                                                            {t('organizationPatients.fullName')}
                                                                        </th>
                                                                        <th scope="col" className="hidden-mobile-tablet font-medium	 py-3 px-4">
                                                                            {t('organizationPatients.dateOfBirth')}

                                                                        </th>
                                                                        <th scope="col" className="font-medium py-3 w-6" />
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {patientList.map((item: any) => {
                                                                        return (
                                                                            <tr
                                                                                // onClick={() => handleViewDetail(item?.id)}
                                                                                key={`${item.id}-list-organization`}
                                                                                className="border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-bg-hover dark:hover:bg-gray-600 cursor-pointer"
                                                                            >
                                                                                <td className="py-2 px-4 flex flex-row itemx-center text-primary-name font-medium text-gray-900 dark:text-white cursor-pointer">
                                                                                    <LabelUser item={item} />
                                                                                </td>
                                                                                <td className="hidden-mobile-tablet text-black py-2 px-4">
                                                                                    {item?.dateOfBirth ? moment(item?.dateOfBirth).format('ll') : ''}
                                                                                </td>
                                                                                <td className="">
                                                                                    <Actions>
                                                                                        <Actions.Item
                                                                                            show
                                                                                            action={() => handleRemovePatient(item?.id)}
                                                                                            icon={<HiOutlineTrash className="w-7 h-5  text-on-primary-container" strokeWidth={2.5} />}
                                                                                        />
                                                                                    </Actions>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                                {(isLoading || isFetching) && <Spinner />}
                                                            </table>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            {totalEntities > 0 && <PaginateTable setCurrentPage={setCurrentPage} currentPage={currentPage} totalEntities={totalEntities} pageCount={5} />
                                            }
                                        </div>
                                    )}
                                </div>
                            </Tabs.Item>
                        </Tabs>
                    </div>
                    <div className='flex justify-center items-center'>
                        <AssignPatient />
                    </div>
                </>
            )
            }
        </div >
    );
};

export default TherapistDetail;
