import { orgTypes } from 'utils/proptypes';
import PaginateTable from 'components/table/paginate';
import { HiOutlineDuplicate } from 'react-icons/hi';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getOrganizationByFilter, updateStatusOrganizationById } from 'api/organizationApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { initOrganization } from 'utils/initData';
import SpinnerComponent from 'components/spinner';
import { apiStatus, exportFileName, defaultpageCount, permissionKeys, routePaths, sortByTypeType } from 'utils/constants';
import OrganizationModal from 'components/modal/organizationModal';
import { isEmpty, isEqual } from 'lodash';
import NoResult from 'components/commonComponent/noResult';
import { toast } from 'react-toastify';
import { checkPermission, exportToFile, messageErrors, copyToClipboard } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import ActionTable from 'components/table/actionTable';
import { filterSelector, orgSelector, userSelector } from 'redux/selectors';
import useUser from 'hooks/useUser';
import '../../styles/styles.scss';
import './organizationList.scss'
import Actions from '../../components/actions';
import Sort from 'components/table/sort';
import Table from 'components/table/table';
import moment from 'moment';
import { filterAction } from 'redux/actions';

const OrganizationList = (props: any) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { WRITE_ORG, PLATFORM_ADMIN } = permissionKeys;
  const [t] = useTranslation();
  const { organizationId } = useSelector(orgSelector);
  const { handleSetCookie } = useUser();
  const { userInfo } = useSelector(userSelector);
  const location = useLocation();
  const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
  const [totalEntities, setTotalEntities] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [organizations, setOrganizations] = useState([initOrganization]);
  const [allOrgs, setAllOrgs] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [sortByType, setSortByType] = useState('');
  const navigate = useNavigate();
  const isWriteOrg = checkPermission(userInfo, props.type, [WRITE_ORG], organizationId);
  const [pageCount, setPageCount] = useState(defaultpageCount);

  const handleAddOrganization = () => {
    setOpenModal(true);
    setIsEdit(false);
  };

  const onRowClickHandler = async (org: orgTypes, tab?: string) => {
    if (checkPermission(userInfo, props.type, [PLATFORM_ADMIN], organizationId)) {
      await handleSetCookie(org.id);
      navigate(routePaths.ORG_OVERVIEW_PAGE, { state: { tab } });
    }
  };

  const { data, isLoading, refetch } = useQuery(
    ['getOrganizationsFilter', currentPage, searchValue, sortBy, sortByType, pageCount],
    () => getOrganizationByFilter({ page: currentPage, limit: pageCount, searchQuery: searchValue, sortBy, sortByType }),
    {
      onSuccess: (data: any) => {
        setTotalEntities(data?.data.totalEntities);
      },
      onError: () => setOrganizations([initOrganization]),
      staleTime: Infinity,
    },
  );

  const getAllOrgs = useMutation('getAllOrganizations', {
    mutationFn: getOrganizationByFilter,
    onSuccess: ({ data }) => setAllOrgs(data.entities),
    onError: error => {
      const message: string = messageErrors(error, t);
      toast.error(message);
    },
  });

  const handleSearch = (value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue: value}));

  const handleOnClickExport = () => {
    if (organizations[0] !== initOrganization && organizations.length > 0) {
      getAllOrgs.mutate({ page: 0, limit: totalEntities, searchQuery: searchValue, sortBy, sortByType });
    }
  };

  const handleActive = async (item: orgTypes) => {
    const { status } = await updateStatusOrganizationById({ id: item.id, isActive: !item.isActive });
    if (status === apiStatus.NO_CONTENT) {
      refetch();
      queryClient.invalidateQueries('getOrganizationDetail');
      queryClient.invalidateQueries('getUserDetail');
    }
  };

  const handleCopyToClipboard = (e: any, item: any) => {
    copyToClipboard(item.name, t('organizationPage.copiedName'));
    e.stopPropagation();
  };

  const handleHeaderSort = (field: string) => {
    setSortBy(field);
    let type = '';
    if (field !== sortBy) {
      type = sortByTypeType.ASC;
    } else if (sortByType === sortByTypeType.ASC) {
      type = sortByTypeType.DESC;
    } else {
      setSortBy(type);
    }
    setSortByType(type);
  };

  useEffect(() => {
    if (!isEmpty(allOrgs)) {
      const exportedData = allOrgs.map((or: any) => ({ name: or.name }));
      exportToFile(exportedData, exportFileName.ORGANIZATION);
    }
    // eslint-disable-next-line
  }, [allOrgs]);

  useEffect(() => {
    if (data !== undefined) {
      setOrganizations(data?.data?.entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTotalEntities(data?.data.totalEntities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <>
      <div className="w-full organization">
        <div className='py-5 mb-2.5'>
          <ActionTable
            placeholderSearch={t('Search for organizations')}
            buttonName={t('Add Organization')}
            handleAddClick={isWriteOrg && handleAddOrganization}
            handleSearch={handleSearch}
            searchValue={searchValue}
          />
        </div>
        {!isEqual(organizations[0], initOrganization) && totalEntities === 0 && <NoResult />}
        {!isEqual(organizations[0], initOrganization) && organizations.length > 0 && (
          <div className="bg-white h-full organization-table">
            <Table className="border-b table-auto">
              <Table.Head className="text-sm text-gray-600 font-semibold border-b-2 border-[#f9f5f5]">
                <Table.HeadCell className="cursor-pointer normal-case bg-white px-[15px]" onClick={() => handleHeaderSort('name')}>
                  <div className="text-black font-medium text-xs flex items-center">
                    {t('organizationPage.organization')}
                    <Sort check={sortBy === 'name'} sortByType={sortByType} />
                  </div>
                </Table.HeadCell>
                <Table.HeadCell
                  className="text-black hidden-mobile font-semibold bg-white w-[150px]"
                  onClick={() => {
                    handleHeaderSort('countUser');
                  }}
                >
                  <div className="flex items-center justify-center normal-case font-medium text-xs">
                    {t('organizationPage.countOfUsers')}
                    <Sort check={sortBy === 'countUser'} sortByType={sortByType} />
                  </div>
                </Table.HeadCell>
                <Table.HeadCell
                  className="text-black hidden-mobile cursor-pointer font-semibold normal-case bg-white w-[150px]"
                  onClick={() => {
                    handleHeaderSort('isActive');
                  }}
                >
                  <div className="flex items-center font-medium text-xs">
                    {t('organizationPage.status')}
                    <Sort check={sortBy === 'isActive'} sortByType={sortByType} />
                  </div>
                </Table.HeadCell>
                  <Table.HeadCell
                  className="text-black hidden-mobile cursor-pointer font-semibold normal-case bg-white w-[180px]"
                  onClick={() => {
                    handleHeaderSort('stripeSubscriptionExpireDate');
                  }}
                >
                  <div className="flex items-center font-medium text-xs">
                    {t('Subscription Status')}
                    <Sort check={sortBy === 'stripeSubscriptionExpireDate'} sortByType={sortByType} />
                  </div>
                </Table.HeadCell>
                <Table.HeadCell className="w-6 bg-white px-[15px] ">{''}</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {organizations.map((item: orgTypes, index) => (
                  <Table.Row key={`row-${index + 1}`} className="bg-white text-base hover:bg-[#f4f8f9] border-b">
                    <Table.Cell className="font-semibold px-[15px] py-2.5">
                      <div className="flex flex-row items-center cursor-pointer" onClick={() => onRowClickHandler(item)}>
                        <div className="text-black font-semibold text-sm">{item.name}</div>
                        <div className="copy-icon pl-3 cursor-pointer text-gray-800">
                          <HiOutlineDuplicate size={18} onClick={(e: any) => handleCopyToClipboard(e, item)} />
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell className="text-black hidden-mobile py-2.5 text-sm flex justify-center pr-[40px]">
                      {item.userOrganizations.length > 0 ? item.userOrganizations.length : '-'}
                    </Table.Cell>
                    <Table.Cell className="text-gray-900 hidden-mobile py-2.5">
                      <aside className={`flex flex-row items-center text-sm ${item.isActive ? 'text-[#0e9f6e]' : 'text-[#e02424]'}`}>
                        <span className={`w-2.5 h-2.5 flex ${item.isActive ? 'bg-[#0e9f6e]' : 'bg-[#e02424]'} rounded-lg mr-2`} />
                        {t(item.isActive ? 'active' : 'inactive')}
                      </aside>
                    </Table.Cell>
                          <Table.Cell className="text-gray-900 hidden-mobile py-2.5">
                      <aside className={`flex flex-row items-center text-sm ${(moment(item?.stripeSubscriptionExpireDate) > moment(new Date()) || item?.stripeSubscriptionExpireDate === null )  ? 'text-[#0e9f6e]' : 'text-[#e02424]'}`}>
                        <span className={`w-2.5 h-2.5 flex ${item.isActive ? 'bg-[#0e9f6e]' : 'bg-[#e02424]'} rounded-lg mr-2`} />
                        {t((moment(item?.stripeSubscriptionExpireDate) > moment(new Date()) || item?.stripeSubscriptionExpireDate === null ) ? 'active' : 'inactive')}
                      </aside>
                    </Table.Cell>
                    <Table.Cell className="px-[15px] py-2.5">
                      {isWriteOrg && (
                        <Actions>
                          <Actions.Item action={() => onRowClickHandler(item)} label={t('userManagementPage.overview')} />
                          <Actions.Item action={() => onRowClickHandler(item, 'members')} label={t('userManagementPage.members')} />
                          <Actions.Item action={() => onRowClickHandler(item, 'edit')} label={t('userManagementPage.edit')} />
                          <Actions.Item action={() => handleActive(item)} label={item.isActive ? t('deactivate') : t('activate')} />
                        </Actions>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {isLoading && <SpinnerComponent />}
            <div className='py-8'>
              <PaginateTable
                setCurrentPage={(value: any) => dispatch(filterAction.setFilter({key: location.pathname, searchValue, currentPage: value}))}
                currentPage={currentPage}
                totalEntities={totalEntities}
                exportHandler={handleOnClickExport}
                pageCount={pageCount}
                setPageCount={setPageCount}
              />
            </div>
          </div>
        )}
      </div>
      {openModal && (
        <OrganizationModal
          headerTitle={isEdit ? t('organizationPage.editOrganization') : t('organizationPage.addOrganization')}
          openModal={openModal}
          isEdit={isEdit}
          setOpenModal={setOpenModal}
          queryClient={queryClient}
        />
      )}
    </>
  );
};

export default OrganizationList;
