/* eslint-disable react/jsx-props-no-spreading */
import { useMutation, useQuery } from 'react-query';
import CustomModalHeader from 'components/modal/customModalHeader';
import GroupButton from 'components/button/groupButton';
import { toast } from 'react-toastify';
import { handleTrimString, messageErrors } from 'utils/utils';
import useUser from 'hooks/useUser';
import { Modal } from 'flowbite-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import './organizationTherapistModal.scss'
import { optionType, roleType } from 'utils/proptypes';
// import { getUsersByEmail, createOrgUser } from 'api/userApi';
import { HiCheck } from 'react-icons/hi';
import { filter, flattenDeep, isEmpty, omit, omitBy } from 'lodash';
import { roleName } from 'utils/constants';
import InputText from 'components/inputText';
import Select from 'components/select';
import { createOrganizationUser, getUsersByEmail } from 'api/userApi';
import PatientInviteModal from './patientInviteModal';
import ConfirmAdditionalCostModal from './confirmAdditionalCostModal';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { filterSelector, orgSelector, userSelector } from 'redux/selectors';
import { filterAction } from 'redux/actions';
import { useLocation } from 'react-router-dom';

type organizationModalProps = {
    openModal: boolean,
    setOpenModal: Function,
    targetData: any,
    headerTitle?: string,
    isEdit: boolean,
    queryClient?: any,
    setCurrentPage?: any,
    roles?: any,
};
const OrganizationTherapistModal = (props: organizationModalProps) => {
    const { openModal, setOpenModal, targetData, headerTitle, queryClient, roles } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchValue, currentPage } = useSelector((state: any) => filterSelector(state, location.pathname));
    const { fetchCurrentUser } = useUser();
    const { userInfo } = useSelector(userSelector);
    const { organizationId } = useSelector(orgSelector)
    const [t] = useTranslation();
    const rootRef = useRef<HTMLDivElement>(null);
    const [emailInput, setEmailInput] = useState<any>('')
    const [selectedOption, setSelectedOption] = useState([]);
    const [openInviteModal, setOpenInviteModal] = useState(false);
    const [openConfirmCostModal, setOpenConfirmCostModal] = useState(false);
    const [isReinvite, setIsReinvite] = useState(false);
    const isSiteAdmin = userInfo?.roles.find((role: any) => role.displayName === roleName.SITE_ADMIN)

    const roleSelectOptions: any =
        roles.data?.entities
            ?.filter((role: any) => role.displayName === roleName.THERAPIST || role.displayName === roleName.ORGANIZATION_ADMIN)
            .map((r: roleType) => ({ value: r.id, label: r.displayName }));

    const handleAddTherapist = async (values: any) => {
        await createOrganizationUser(values);
    };

    const ValidateSchema = Yup.object().shape({
        emailAddress: Yup.string().email(t('userManagementPage.invalidEmail')).required(t('userManagementPage.requiredField')),
        roleIds: Yup.array().min(1, t('userManagementPage.roleMin')).required(t('userManagementPage.requiredField')),
    });

    const hookForm = useForm({
        mode: "onChange",
        resolver: yupResolver(ValidateSchema)
    });

    const {
        formState: { errors },
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        clearErrors,
        setError
    } = hookForm

    const checkEmailIsExist = () => {
        if (!errors.emailAddress) {
            const email = getValues('emailAddress')
            setEmailInput(email)
        }
    }
    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            checkEmailIsExist()
        }
    }

    const handleChange = (options: any = []) => {
        const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
        setSelectedOption(noDefaultOptions);
        const roleIds = noDefaultOptions.map((option: optionType) => option.value);
        setValue('roleIds', roleIds);
        if (roleIds.length > 0) {
            clearErrors('roleIds');
        } else {
            setError('roleIds', {
                type: 'required',
                message: t('userManagementPage.requiredField'),
            });
        }
    };

    const handleChangeCredentials = (options: any = []) => {
        const noDefaultOptions: any = filter(flattenDeep([options]), o => !!o);
        setSelectedOption(noDefaultOptions);
        const credentialIds = noDefaultOptions.map((option: optionType) => option.value);
        setValue('credentialIds', credentialIds);
        if (credentialIds.length > 0) {
            clearErrors('credentialIds');
        }
    };

    const mutation = useMutation('createOrganizationTherapist', {
        mutationFn: handleAddTherapist,
        onSuccess: () => {
            toggleHandler()
            if (currentPage !== 0) {
                dispatch(filterAction.setFilter({key: location.pathname, searchValue}));
            };
            queryClient.invalidateQueries('getOrganizationTherapists', { refetchActive: true }, { cancelRefetch: true });
            const message: string = isReinvite ? t('organizationTherapists.reinvited') : t('organizationTherapists.addTherapistMessage');
            toast.success(message);
            fetchCurrentUser();
            setIsReinvite(false)
        },
        onError: (error) => {
            const message: string = messageErrors(error, t);
            toast.error(message);
        }
    });


    const { data } = useQuery(['checkEmailExist', emailInput], () => getUsersByEmail(emailInput),
        {
            staleTime: Infinity,
            enabled: emailInput !== '',
            retry: false,
            onError: (error) => { setIsReinvite(false) }
        },
    )

    const onSubmit = async (values: any) => {
        try {
            const { data }: any = await getUsersByEmail(values.emailAddress);
            const isExistInOrg = data?.organizations?.find((org: any) => org.organizationId === organizationId)
            
            if (!data?.authId && isExistInOrg) {
                setIsReinvite(true);
                setOpenInviteModal(true);
                return;
            } else if ( (data?.authId && isSiteAdmin ))
               {
                    onSave();
                    return;
             } else  setOpenConfirmCostModal(true)
            // let payload = {
            //     ...values,
            //     firstName: isEmpty(values.firstName) ? '' : values.firstName,
            //     lastName: isEmpty(values.lastName) ? '' : values.lastName,
            // };
            // payload = omitBy(payload, v => v === '')
            // mutation.mutate({ payload, id: targetData?.id });

        } catch (error) {
            if (!isSiteAdmin) {
            setOpenConfirmCostModal(true)
            } else onSave()
        }
    };

    const onSave = async () => {
        const values = handleTrimString(getValues())
        let payload = {
            ...values,
            firstName: isEmpty(values.firstName) ? '' : values.firstName,
            lastName: isEmpty(values.lastName) ? '' : values.lastName,
        };
        payload = omitBy(payload, v => v === '')

        mutation.mutate({ payload, id: targetData?.id });
    };

    const toggleHandler = () => {
        setOpenModal(!openModal)
        reset()
    }

    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data) {
            setValue('firstName', data?.data?.firstName)
            setValue('lastName', data?.data?.lastName)
            clearErrors('firstName');
            clearErrors('lastName');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const credentials = [
        {
            value: 1,
            label: 'PTA'
        },
        {
            value: 2,
            label: 'PT'
        },
        {
            value: 3,
            label: 'DC'
        },
    ]
    return (
        <div ref={rootRef} className="relative">
            <Modal
                className='modal-organization-therapist'
                show={true}
                size="md"
                popup={true}
                root={rootRef.current ?? undefined}
                onClose={toggleHandler}
                dismissible={true}>
                <CustomModalHeader title={headerTitle} toggle={toggleHandler} />
                <Modal.Body className='modal-body'>
                    {!isSiteAdmin && (
                        <p className='text-center text-sm text-red-500'>
                        <Trans i18nkey='When add Therapist or OrgAdmin, you will cost an addition $15/mo.'>
                            {t('When add Therapist or OrgAdmin, you will cost an addition $15/mo.')}
                        </Trans>
                    </p>
                    )}
                   
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`${errors.emailAddress && 'border-error'} pt-4 px-5`} onBlur={() => checkEmailIsExist()}>
                            <InputText hookForm={hookForm} placeholder={`${t('userProfilePage.email')} *`} name="emailAddress" isHolderLabel={true} />
                            {data?.data && !errors.emailAddress && (
                                <div className='flex items-center'>
                                    <HiCheck className='w-6 h-6 mr-2 text-green-600 ' />
                                    <div className={`text-green-600 text-xs font-normal mt-1 flex`}>
                                        {t('userManagementPage.emailExist')}</div>
                                </div>
                            )}
                        </div>
                        <div className={`${errors?.firstName && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder={t('userProfilePage.firstName')} name="firstName" isHolderLabel={true} />
                        </div>
                        <div className={`${errors?.lastName && 'border-error'} pt-4 px-5`}>
                            <InputText hookForm={hookForm} placeholder={t('userProfilePage.lastName')} name="lastName" isHolderLabel={true} />
                        </div>
                        <div className="custom-multi-select mt-2.5 pt-4 px-5">
                            <div>
                                <Select
                                    options={roleSelectOptions}
                                    onChange={(option: any) => handleChange(option)}
                                    placeholder={`${t('userProfilePage.role')} *`} isHolderLabel={true}
                                    value={selectedOption}
                                    errors={errors}
                                    name="roleIds"
                                    isMulti={false}
                                />
                            </div>
                        </div>
                        {/* <div className="custom-multi-select mt-2.5 pt-4 px-5">
                            <div>
                                <Select
                                    options={credentials}
                                    onChange={(option: any) => handleChangeCredentials(option)}
                                    placeholder={`Credentials`} isHolderLabel={true}
                                    value={selectedOption}
                                    errors={errors}
                                    name="credentialIds"
                                    isMulti={true}
                                />
                            </div>
                        </div> */}
                        <div className="flex justify-center items-center pt-6 px-5 ">
                            <GroupButton
                                className="items-center justify-center pb-2"
                                buttons={[
                                    {
                                        type: 'button',
                                        text: t('modal.cancel'),
                                        classType: 'white',
                                        action: () => toggleHandler(),
                                    },
                                    {
                                        type: 'submit',
                                        text: t('modal.saveAndPay'),
                                        classType: 'blue',
                                    },
                                ]}
                            />
                        </div>
                    </form>
                </Modal.Body>
                <PatientInviteModal openInviteModal={openInviteModal} setOpenInviteModal={setOpenInviteModal} isReinvite={isReinvite} onSubmit={onSave} isLoading={mutation.isLoading} />
                {(!isReinvite && !isSiteAdmin) && (
                <ConfirmAdditionalCostModal openConfirmCostModal={openConfirmCostModal } setOpenConfirmCostModal={setOpenConfirmCostModal} onSubmit={onSave} isLoading={mutation.isLoading} />
                )}
            </Modal >
        </div>
    );;
};
export default OrganizationTherapistModal;
